













import { Component, Vue } from "vue-property-decorator";
import EmptyBox from "@/components/EmptyBox.vue";
import AddressForm from "@/components/AddressForm.vue";
import { IAddress, MineApi } from "@/api/mine.api";
import { Toast } from "vant";
import BlindBoxApi from "@/api/blind-box.api";
import { observer } from "@/utils/common.util";

@Component({
  components: { AddressForm, EmptyBox },
})
export default class ApplyDelivery extends Vue {
  id = this.$route.params.id;
  address: IAddress = {
    address_id: 0,
    consignee: "",
    phone: "",
    provinces: null,
    city: null,
    area: null,
    detailed_address: "",
    zip_code: "",
  };

  get show(): boolean {
    return true;
  }

  set show(status: boolean) {
    if (status) return;
    this.$router.replace({
      name: "MyVoucherEntityDetail",
      params: { id: String(this.id) },
    });
  }

  async mounted(): Promise<void> {
    observer("refreshInit", () => this.init());
    await this.init();
  }

  async init() {
    // if (!this.id) return;
    const toast = Toast.loading({ mask: true });
    this.address = await MineApi.getDeliverAddress();
    toast.close();
  }

  async addressComplete(params: IAddress): Promise<void> {
    this.address = params;
    const toast = Toast.loading();
    const result = await BlindBoxApi.applyDeliver(this.id, this.address)
      .then(() => undefined)
      .catch((err) => err.message);
    toast.close();
    if (result) {
      Toast.fail(result);
      return;
    }
    await this.$router.replace({ name: "mysupply", query: { num: "2" } });
  }
}
