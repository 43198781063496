






































































import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch,
} from "vue-property-decorator";
import { CellGroup, Cell, Popup, Field, Area, Toast } from "vant";
import { AreaList } from "@/lib/area";
import { IAddress } from "@/api/mine.api";

@Component({
  components: {
    CellGroup,
    Cell,
    Popup,
    Field,
    VanArea: Area,
  },
})
export default class AddressForm extends Vue {
  @Model("update:show", { default: false })
  private readonly show!: boolean;

  @Prop() private readonly addressItem!: IAddress;
  @Prop({ default: true }) private readonly closeable!: boolean;
  @Prop({ default: true }) private readonly completeClose!: boolean;

  showPicker = false;
  areaList = AreaList;
  address: IAddress = {
    address_id: 0,
    consignee: "",
    phone: "",
    provinces: null,
    city: null,
    area: null,
    detailed_address: "",
    zip_code: "10000",
  };

  @Watch("addressItem")
  watchVisible(address: IAddress): void {
    this.address = {
      ...address,
      zip_code: "10000",
    };
  }

  handleConfirmAddress(): void {
    const isEmpty = (key: keyof IAddress, tip: string) => {
      if (this.address[key]) return;
      Toast(tip);
      throw new Error(tip);
    };
    isEmpty("consignee", "请填写收件人");
    isEmpty("phone", "请填写手机号");
    isEmpty("provinces", "请选择所在地区");
    isEmpty("detailed_address", "请填写详细地址");
    this.complete();
    if (this.completeClose) this.visible = false;
  }

  @Emit()
  complete(): IAddress {
    return this.address;
  }

  get addressText(): string {
    const { provinces = [], city = [], area = [] } = this.address;
    const getString = (data: string[] | null) => {
      if (!data) return "";
      if (data[1]) return data[1];
      return "";
    };
    const _provinces = getString(provinces);
    const _city = getString(city);
    const _area = getString(area);
    return `${_provinces}${_city && `/${_city}`}${_area && `/${_area}`}`;
  }

  get addressCode(): string {
    const { provinces = [], city = [], area = [] } = this.address;
    const getCode = (data: string[] | null) => {
      if (!data) return false;
      return data[0] || false;
    };
    const _provinces = getCode(provinces);
    const _city = getCode(city);
    const _area = getCode(area);
    if (_area) return _area;
    if (_city) return _city;
    if (_provinces) return _provinces;
    return "";
  }

  handleConfirm(data: { code: string; name: string }[]): void {
    const _init = { code: "", name: "" };
    const [provinces = _init, city = _init, area = _init] = data;
    const getAddress = (data: { code: string; name: string } | null) => {
      if (!data) return [];
      const { code, name } = data;
      if (!code || !name) return [];
      return [code, name];
    };
    const _provinces = getAddress(provinces);
    const _city = getAddress(city);
    const _area = getAddress(area);
    if (provinces.name !== "海外" && (!city.code || !area.code)) {
      Toast("请选择地区");
      return;
    }
    if (provinces.name === "海外" && !city.code) {
      Toast("请选择地区");
      return;
    }
    this.address = {
      ...this.address,
      provinces: _provinces,
      city: _city,
      area: _area,
    };
    this.showPicker = false;
  }

  get visible(): boolean {
    return this.show;
  }

  set visible(val: boolean) {
    this.handleUpdateShow(val);
  }

  @Emit("update:show")
  handleUpdateShow(show: boolean): boolean {
    return show;
  }
}
